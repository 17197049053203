import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import DynamicTable from '../../../../components/DynamicTable/DynamicTable'
import {projectMemberListModalColumns} from '../../utils'
import {useGetTeamMemberDetailsById} from '../../hooks/teams/useGetProjectsTeamMembersById'
import Initials from '../../../../components/Initials'

const ProjectMembersListModal = ({show, onClose, teamId}: any) => {
  const {teamMemberDetails, isLoading} = useGetTeamMemberDetailsById(teamId)

  return (
    <ConfirmationModal
      show={show}
      onClose={onClose}
      title={'Members'}
      bodyClass='mh-550px overflow-auto'
      body={
        <DynamicTable
          data={teamMemberDetails}
          sortableColumns={projectMemberListModalColumns}
          loading={isLoading}
          tableClass='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2'
          noDataMessage='No project members found'
          TableRow={({row}: any) => {
            return (
              <tr>
                <td>
                  <div className='d-flex align-items-center flex-grow-1'>
                    <div className='symbol symbol-circle symbol-40px me-3'>
                      <Initials text={row?.name?.charAt(0).toUpperCase()} />
                    </div>
                    <div className='d-flex flex-column'>
                      <div className='align-items-center fw-bold'>{row['name']}</div>
                      <div className='align-items-center'>{row['username']}</div>
                    </div>
                  </div>
                </td>{' '}
                <td>
                  <div className='text-end'>{row['role']}</div>
                </td>
              </tr>
            )
          }}
        />
      }
    />
  )
}

export default ProjectMembersListModal
