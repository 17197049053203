export const getBadgeColor = (text: string, type: string) => {
  const normalizedText = text?.toLowerCase()
  switch (normalizedText) {
    case 'active':
      return `badge-${type}-success`
    case 'inactive':
      return `badge-${type}-danger`
    case 'awaiting payment':
      return `badge-${type}-danger`
    case 'shipped':
      return `badge-${type}-success`
    case 'refunded':
      return `badge-${type}-success`
    case 'incomplete':
      return `badge-${type}-success`
    case 'pending':
      return `badge-${type}-warning`
    case 'partially shipped':
      return `badge-${type}-success`
    case 'cancelled':
      return `badge-${type}-danger`
    case 'declined':
      return `badge-${type}-danger`
    case 'awaiting pickup':
      return `badge-${type}-danger`
    case 'awaiting shipment':
      return `badge-${type}-danger`
    case 'completed':
      return `badge-${type}-success`
    case 'awaiting fulfillment':
      return `badge-${type}-danger`
    case 'manual verification required':
      return `badge-${type}-danger`
    case 'disputed':
      return `badge-${type}-success`
    case 'partially refunded':
      return `badge-${type}-success`
    case 'true':
      return `badge-${type}-success`
    case 'false':
      return `badge-${type}-danger`
    case 'scheduled':
      return `badge-${type}`
    case 'draft':
      return `badge-secondary`
    case 'archived':
      return `badge-${type}-danger`
    case 'failed':
      return `badge-${type}-danger`
    case 'partially fulfilled':
      return `badge-${type}-primary`
    case 'deleted':
      return `badge-${type}-danger`
    case 'published':
      return `badge-${type}-success`
    case 'error':
      return `badge-${type}-danger`
    case 'publishing':
      return `badge-${type}-primary`
    case 'new':
      return `badge-${type}-primary`
    case 'todo':
      return `badge-secondary`
    case 'success':
      return `badge-${type}-success`
    case 'low':
      return `badge-${type}-success`
    case 'medium':
      return `badge-${type}-warning`
    case 'high':
      return `badge-${type}-danger`
    default:
      return `badge-${type}`
  }
}

export const getJobDetailsBadgeColor = (text: string, type: string) => {
  switch (text) {
    case 'Completed':
      return `badge-${type}-success`
    case 'Success':
      return `badge-${type}-success`
    case 'Failed':
      return `badge-${type}-danger`
    default:
      return ``
  }
}

export const getBlockedOrderBadgeColor = (text: string, type: string) => {
  switch (text) {
    case 'Hold Inventory':
      return `badge-${type}-danger`
    case 'Consignment':
      return `badge-${type}-primary`
    case 'Complete':
      return `badge-${type}-success`
    default:
      return ``
  }
}

export const getInquiryBadgeColor = (text: string, type: string) => {
  switch (text) {
    case 'New':
      return `badge-${type}-primary` // Blue
    case 'To Do':
      return `badge-secondary` // Grey
    case 'In Progress':
      return `badge-${type}-warning` // Yellow
    case 'Pending':
      return `badge-${type}-danger` // Red
    case 'Done':
      return `badge-${type}-success` // Green
    case 'Archived':
      return `badge-secondary` // Grey
    default:
      return ''
  }
}
