import React, {useContext, useState} from 'react'
import SpentTimeModal from '../modals/SpentTimeModal'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {TicketDetailsPageContext} from '../../context'
import {convertUTCToLocal, formatDate} from '../../../../utils/date'
import {useAppSelector} from '../../../../redux/useTypedSelector'

const SpentTimeTab: React.FC = () => {
  const {ticketSpentTimeHistory, isSpentTimeLoading} = useContext(TicketDetailsPageContext)
  const [visibleIndex, setVisibleIndex] = useState<number | null>(null)
  const [openModal, setOpenModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectData, setSelectData] = useState<any>({})
  const {user} = useAppSelector((state) => state.auth)
  const {deleteSpentTime, isTimeLogOperationLoading} = useContext(TicketDetailsPageContext)

  const onClose = () => {
    setOpenModal(false)
    setShowDeleteModal(false)
  }

  const handleDeleteConfirm = (id: any) => {
    deleteSpentTime(id)
  }

  return (
    <div>
      {ticketSpentTimeHistory?.map((item, index) => (
        <div
          key={index}
          className='rounded bg-hover-light p-5 mb-5'
          onMouseEnter={() => setVisibleIndex(index)}
          onMouseLeave={() => setVisibleIndex(null)}
          style={{backgroundColor: visibleIndex === index ? '#f8f9fa' : 'transparent'}}
        >
          <div className='d-flex'>
            {/* Hourglass Icon */}
            <div className='border border-gray-400 w-35px h-40px rounded p-3 me-5'>
              <i className='fa-solid fa-hourglass-half fa-lg'></i>
            </div>

            <div className='flex-grow-1'>
              {/* User Info */}
              <div
                className='d-flex justify-content-between align-items-center mb-5 position-relative'
                style={{cursor: 'pointer'}}
              >
                <div className='d-flex align-items-center'>
                  <div className='fw-bold'>{item.created_by_name}</div>
                  <div className='rounded-circle bg-success mx-2 w-10px h-10px'></div>
                  <div className='small text-muted'>
                    <small
                      className='text-muted'
                      title={convertUTCToLocal(item.spent_time_created_at)}
                    >
                      {convertUTCToLocal(item.spent_time_created_at, true, true)}
                    </small>
                  </div>
                </div>

                {/* Edit and Delete Icons */}
                <div
                  className={`d-flex gap-3 position-absolute top-0 end-0 align-items-center ${
                    visibleIndex === index && user?.username === item.created_by ? '' : 'd-none'
                  }`}
                >
                  <i
                    className='bi bi-pencil text-success'
                    title='Edit'
                    onClick={() => {
                      setOpenModal(true)
                      setSelectData(item)
                    }}
                  ></i>
                  <i
                    className='bi bi-trash text-danger'
                    title='Delete'
                    onClick={() => {
                      setShowDeleteModal(true)
                      setSelectData(item)
                    }}
                  ></i>
                </div>
              </div>

              {/* Work Type and Description */}
              <div className='row'>
                <div className='col-3 border-gray-300 border-end'>
                  <div className='fs-5 fw-bold'>{item.spent_time_diff}</div>
                </div>
                <div className='col-3 text-muted border-gray-300 border-end'>
                  {formatDate(item.spent_time_created_at, true)}
                </div>
                <div className='col-6'>
                  <p>{item.log_description || '-'}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      {openModal && (
        <SpentTimeModal
          openModal={openModal}
          onClose={onClose}
          isEdit={true}
          logData={selectData}
        />
      )}

      <ConfirmationModal
        uniqueID={selectData?.id}
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onAction={handleDeleteConfirm}
        isOperationLoading={isTimeLogOperationLoading}
        isDataLoading={isSpentTimeLoading}
        body={'Are you sure you want to delete work item?'}
      />
    </div>
  )
}

export default SpentTimeTab
