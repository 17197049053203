import {useState} from 'react'
import {getBadgeColor} from '../../../../utils/badge'
import {formatDate} from '../../../../utils/date'
import {capitalizeFirstLetter} from '../../../../utils/string'
import {ProjectTeamsTableRowTypes} from '../../interfaces'
import ProjectMembersListModal from '../modals/ProjectMembersListModal'

const ProjectTeamsTableRow = ({row, actionComponent}: ProjectTeamsTableRowTypes) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <tr>
      {/* Name Column */}
      <td>{row['name']}</td>

      {/* Members Column */}
      <td className='text-center'>
        <span className='text-decoration-underline text-hover-primary cursor-pointer' onClick={() => setShowModal(true)}>{row['member_counts']}</span>
      </td>

      {/* Projects Column */}
      <td className='text-center'>{row['project_counts']}</td>

      {/* Created Date Column */}
      <td>{formatDate(row['created_at'], false)}</td>

      {/* Status Column */}
      <td>
        <div className='d-flex justify-content-center'>
          <span className={`badge ${getBadgeColor(row?.status, 'light')} badge-lg`}>
            {capitalizeFirstLetter(row?.status)}
          </span>
        </div>
      </td>

      {/* Action Column */}
      {actionComponent && <td>{actionComponent(row)}</td>}
      {showModal && (
        <ProjectMembersListModal
          show={showModal}
          onClose={() => setShowModal(false)}
          teamId={row?.id}
        />
      )}
    </tr>
  )
}

export default ProjectTeamsTableRow
