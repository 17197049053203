import {useContext} from 'react'
import {BoardSettingsPageContext, ProjectDetailsContext} from '../context'
import useColumnOperations from '../hooks/boardSettings/useColumnOperations'
import useCustomFieldsOperations from '../hooks/boardSettings/useCustomFieldsOperations'
import useGetBoardColumnsList from '../hooks/boardSettings/useGetBoardColumnsList'
import useGetBoardCustomFieldsList from '../hooks/boardSettings/useGetBoardCustomFieldsList'
import useGetBoardModulesList from '../hooks/boardSettings/useGetBoardModulesList'
import useModulesOperations from '../hooks/boardSettings/useModulesOperations'
import ColumnsTable from '../sections/tables/boardSettingTables/ColumnsTable'
import CustomFieldsTable from '../sections/tables/boardSettingTables/CustomFieldsTable'
import ModulesTable from '../sections/tables/boardSettingTables/ModulesTable'
import ProjectMappingSection from '../sections/ProjectMappingSection'
import useGetProjectMappingDropdowns from '../hooks/boardSettings/useGetProjectMappingDropdowns'
import useProjectMappingOperations from '../hooks/boardSettings/useProjectMappingOperations'
import useGetProjectMembers from '../hooks/boardSettings/useGetProjectMembers'
import useGetGlobalCustomFieldsList from '../hooks/boardSettings/useGetGlobalCustomFieldsList'

function BoardSetting() {
  const {projectId, projectName, projectStatus, projectDetails, hasProjectPermission} =
    useContext(ProjectDetailsContext)
  const hasBoardSettingsPermission = (hasProjectPermission && projectDetails?.is_owner) || false
  const {projectMembers, isLoading: isLoadingProjectMembers} = useGetProjectMembers()

  const {
    boardColumnsList,
    columnMappingDropdownList,
    isLoading: isLoadingBoardColumnsList,
    isLoadingColumnMappingDropdownList,
  } = useGetBoardColumnsList()

  const {boardModulesList, isLoading: isLoadingBoardModulesList} = useGetBoardModulesList()

  const {
    boardCustomFieldsList,
    customFieldMappingDropdownList,
    isLoading: isLoadingBoardCustomFieldsList,
    isLoadingCustomFieldMappingDropdownList,
  } = useGetBoardCustomFieldsList()

  const {globalCustomFieldsList, isLoading: isLoadingGlobalCustomFieldsList} =
    useGetGlobalCustomFieldsList()

  const {
    projectTablesDropdownList,
    isLoadingProjectTablesDropdownList,
    tableColumnsDropdownList,
    isLoadingTableColumnsDropdownList,
  } = useGetProjectMappingDropdowns()

  const {
    createColumn,
    updateColumn,
    updateColumnTableSorting,
    deleteColumn,
    mapColumnStatus,
    columnOperationId,
    isLoading: isLoadingColumnOperation,
  } = useColumnOperations()

  const {
    createCustomField,
    updateCustomField,
    updateCustomFieldTableSorting,
    deleteCustomField,
    mapCustomFieldStatus,
    customFieldOperationId,
    isLoading: isLoadingCustomFieldOperation,
  } = useCustomFieldsOperations()

  const {updateProjectMapping, isLoading: isLoadingProjectMappingOperations} =
    useProjectMappingOperations()

  const {
    createModule,
    updateModule,
    updateModuleTableSorting,
    deleteModule,
    moduleOperationId,
    isLoading: isLoadingModulesOperation,
  } = useModulesOperations()

  const contextValue = {
    hasBoardSettingsPermission,

    projectId,
    projectName,
    projectStatus,

    createColumn,
    updateColumn,
    updateColumnTableSorting,
    deleteColumn,
    mapColumnStatus,
    columnOperationId,
    isLoadingColumnOperation,

    createModule,
    updateModule,
    updateModuleTableSorting,
    deleteModule,
    moduleOperationId,
    isLoadingModulesOperation,

    createCustomField,
    updateCustomField,
    updateCustomFieldTableSorting,
    deleteCustomField,
    mapCustomFieldStatus,
    customFieldOperationId,
    isLoadingCustomFieldOperation,

    boardColumnsList,
    isLoadingBoardColumnsList,

    columnMappingDropdownList,
    isLoadingColumnMappingDropdownList,

    boardModulesList,
    isLoadingBoardModulesList,

    boardCustomFieldsList,
    isLoadingBoardCustomFieldsList,

    customFieldMappingDropdownList,
    isLoadingCustomFieldMappingDropdownList,

    globalCustomFieldsList,
    isLoadingGlobalCustomFieldsList,

    projectTablesDropdownList,
    isLoadingProjectTablesDropdownList,

    tableColumnsDropdownList,
    isLoadingTableColumnsDropdownList,

    projectMembers,
    isLoadingProjectMembers,

    updateProjectMapping,
    isLoadingProjectMappingOperations,
  }

  return (
    <BoardSettingsPageContext.Provider value={contextValue}>
      <div className='d-flex flex-column gap-20'>
        <ProjectMappingSection />
        <ColumnsTable />
        <ModulesTable />
        <CustomFieldsTable />
      </div>
    </BoardSettingsPageContext.Provider>
  )
}

export default BoardSetting
