import {useState} from 'react'
import {CommentsTab} from '../sections/tabs/CommentsTab'
import {ActivitiesTab} from '../sections/tabs/ActivitiesTab'
import SpentTimeTab from '../sections/tabs/SpentTimeTab'

function CommentSectionNavigation() {
  const tabs = [
    {label: 'Comments', id: 'comment-tab', content: <CommentsTab />},
    {label: 'Spent Time', id: 'spent-time-tab', content: <SpentTimeTab />},
    {label: 'History', id: 'activity-tab', content: <ActivitiesTab />},
  ]

  const [activeTab, setActiveTab] = useState(tabs[0].id)

  const handleTabChange = (tabId: any) => {
    setActiveTab(tabId)
  }

  return (
    <div className={'mt-10'}>
      <ul className='nav nav-tabs nav-line-tabs lh-2 fs-6'>
        {tabs.map((tab: any) => (
          <li className='nav-item' key={tab.id}>
            <span
              className={`nav-link cursor-pointer ${
                activeTab === tab.id
                  ? 'active border-bottom border-2 border-primary fw-bold text-primary'
                  : 'fw-bold'
              }`}
              onClick={() => handleTabChange(tab.id)}
            >
              {tab.label}
            </span>
          </li>
        ))}
      </ul>

      <div className='tab-content mt-10'>
        {tabs.map((tab: any) => (
          <div
            className={`tab-pane fade ${activeTab === tab.id ? 'active show' : ''}`}
            key={tab.id}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  )
}

export default CommentSectionNavigation
