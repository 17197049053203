import {useContext} from 'react'
import {TimeSheetReportPageContext} from '../../context'
import {Link} from 'react-router-dom'

const TimeSheetTableRow = ({row, actionComponent}: any) => {
  const {timeSheetMeta} = useContext(TimeSheetReportPageContext)

  // Reverse the meta entries
  const metaArray = Object.entries(timeSheetMeta).map(([key, value]: any) => ({
    key,
    value,
  }))

  // Helper to render distribution values for each day
  const renderSpentTimeDistribution = (distribution: any) => {
    return metaArray.map(({key, value}, index) => {
      const [day] = value.split('/')
      const className = day.includes('S') ? 'bg-light' : ''
      return (
        <td key={index} className={`min-w-100px text-gray-600 ${className}`}>
          {distribution[key] || '0h 0m'}
        </td>
      )
    })
  }

  // Function to calculate the sum of spent time for each day
  const calculateTotalTimeForDay = (tickets: any[], dayKey: string) => {
    return tickets.reduce((total, ticket) => {
      const time = ticket.spent_time_distribution[dayKey]
      if (time) {
        const [hours, minutes] = time.split('h').map((item: any) => item.trim())
        const totalMinutes = parseInt(hours) * 60 + (parseInt(minutes) || 0)
        return total + totalMinutes
      }
      return total
    }, 0)
  }

  // Function to convert minutes to "hh:mm" format
  const convertMinutesToTime = (totalMinutes: number) => {
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60
    return `${hours}h ${minutes}m`
  }

  return (
    <>
      {/* Main Row */}
      <tr>
        {/* Customer Name Column */}
        <td className='fw-bold body-col-sticky border-end'>{row['customer_name']}</td>

        {/* Total Spent Time Column */}
        <td className='fw-bold body-col-sticky border-end'>{row['total_spent_time']}</td>

        {/* Progress Column */}
        {metaArray.map(({key, value}, index) => {
          const [day] = value.split('/')
          const totalMinutes = calculateTotalTimeForDay(row.tickets || [], key)
          const formattedTime = convertMinutesToTime(totalMinutes)
          const className = day.includes('S') ? 'bg-light' : ''

          return (
            <td key={index} className={`min-w-100px ${className} `}>
              <div className='fw-bold'>{formattedTime || '0h 0m'}</div>
            </td>
          )
        })}

        {/* Action Column */}
        {actionComponent && <td>{actionComponent(row)}</td>}
      </tr>

      {/* Nested Table Row */}
      {row.tickets?.length > 0 && (
        <tr>
          <td colSpan={metaArray?.length + 2} className='p-0'>
            <table className='table nested-table p-0 m-0'>
              <tbody>
                {row.tickets.map((ticket: any, index: number) => (
                  <tr key={index}>
                    <td className='min-w-400px w-400px body-col-sticky border-end'>
                      <div>
                        <Link
                          to={`/projects/all-projects/${ticket?.project_id}/ticket/${
                            ticket?.card_id
                          }/${ticket?.card_identifier?.toLowerCase()}`}
                          className='text-gray-800'
                        >
                          <span className='link-primary fw-bolder'>
                            {ticket['card_identifier']}
                          </span>{' '}
                          - <span>{ticket['ticket_title']}</span>
                        </Link>
                      </div>
                    </td>
                    <td className='min-w-120px w-120px text-gray-600 body-col-sticky border-end'>
                      {ticket.spent_time_ticket_wise}
                    </td>
                    {renderSpentTimeDistribution(ticket.spent_time_distribution)}
                  </tr>
                ))}
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  )
}

export default TimeSheetTableRow
