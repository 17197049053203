import useApi from '../../../../services/useApi'
import {boardColumnsData} from '../../parsings'
import {useQuery} from 'react-query'
import {useContext, useState} from 'react'
import {ProjectDetailsContext} from '../../context'

const useGetBoardColumnsList = () => {
  const Api = useApi()
  const {projectId} = useContext(ProjectDetailsContext)
  const {data}: any = useQuery(['projectMappingData']) || null
  const tableId = data?.tableId || null
  const columnKey = data?.columnKey || null
  const [isInitialDropdownListFetched, setIsInitialDropdownListFetched] = useState(false)

  // Get board columns listing
  const {data: response, isFetching} = Api.useGetQuery(`/agile/projects/${projectId}/columns`, {
    queryId: 'board-columns',
  })

  // dropdown list for column mapping
  const {data: dropdownListResponse, isFetching: isLoadingColumnMappingDropdownList} =
    Api.useGetQuery(
      `/agile/projects/dbtable/${tableId}/column/${columnKey}/mapping`,
      {
        queryId: `${tableId}-${columnKey}-status-dropdown-list`,
        onSuccess: () => setIsInitialDropdownListFetched(true),
      },
      {
        enabled: !isInitialDropdownListFetched && !!tableId && !!columnKey,
        cacheTime: 0,
        staleTime: 0,
      }
    )

  return {
    boardColumnsList: boardColumnsData(response?.data),
    columnMappingDropdownList: dropdownListResponse?.data || [],
    isLoading: isFetching,
    isLoadingColumnMappingDropdownList: isLoadingColumnMappingDropdownList,
  }
}

export default useGetBoardColumnsList
