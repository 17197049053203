import {useContext} from 'react'
import {formatDate} from '../../../../../utils/date'
import {ProjectDetailsContext} from '../../../context'
import MemberActionsOverlay from '../../overlays/MemberActionsOverlay'
import Initials from '../../../../../components/Initials'

const MemberPageTableRow = ({row, hasWritePermission}: any) => {
  const {projectDetails} = useContext(ProjectDetailsContext)
  return (
    <tr className={!row?.is_active ? 'opacity-50 bg-light-danger' : ''}>
      <td>
        <div className='d-flex align-items-center'>
          <div className='symbol symbol-circle symbol-40px me-3'>
            <Initials text={row['name']?.charAt(0).toUpperCase()} />
          </div>
          <div>
            <div className='text-dark'>
              {row['name']}
              {row?.is_owner && (
                <span className={`ms-2 badge badge-lg badge-light-primary`}>
                  <div className='align-items-center'>Owner</div>
                </span>
              )}
            </div>
            <div className='text-gray-500'>{row['username']}</div>
          </div>
        </div>
      </td>
      <td>
        <div className='align-items-center'>{row['role']}</div>
      </td>
      <td>
        <div className='text-center'>{row['card_count']}</div>
      </td>
      <td>
        <div className='align-items-center'>{formatDate(row['created_at'], false)}</div>
      </td>
      <td>
        {hasWritePermission &&
          (projectDetails?.is_owner && row?.is_owner ? (
            <MemberActionsOverlay memberDetails={row} />
          ) : row?.card_count === 0 && !row?.is_owner ? (
            <MemberActionsOverlay memberDetails={row} />
          ) : null)}
      </td>
    </tr>
  )
}

export default MemberPageTableRow
