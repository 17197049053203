import {PageLink} from '../../_metronic/layout/core'
import useMeta from '../../hook/useMeta'
import TasksReport from './layouts/TasksReport'
import MembersReports from './layouts/MembersReport'
import TimeSheet from './layouts/TimeSheet'
import Matrix from './layouts/Matrix'
import Navigation from '../../components/Navigation/Navigation'
const ProjectSettingsPageCrumbs: Array<PageLink> = [
  {
    title: 'Projects',
    path: '/projects/all-projects',
    isSeparator: false,
    isActive: false,
  },
  {
    title: 'Settings',
    path: '/projects/settings',
    isSeparator: false,
    isActive: false,
  },
]
const ProjectReportsPage = () => {
  useMeta('Projects Settings')

  return (
    <Navigation
      baseUrl={`/projects/reports`}
      pageTitle={'Reports'}
      breadcrumbs={ProjectSettingsPageCrumbs}
      navigationData={[
        {
          key: 'tasks',
          label: 'Tasks',
          component: <TasksReport />,
          icon: '/media/ad-theme-icons/filter-tasks.svg',
        },
        {
          key: 'members',
          label: 'Members',
          component: <MembersReports />,
          icon: '/media/ad-theme-icons/filter-members.svg',
        },
        {
          key: 'time-sheet',
          label: 'Time Sheet',
          component: <TimeSheet />,
          icon: '/media/ad-theme-icons/filter-timesheet.svg',
        },
        {
          key: 'matrix',
          label: 'Matrix',
          component: <Matrix />,
          icon: '/media/ad-theme-icons/filter-matrix.svg',
        },
      ]}
    />
  )
}

export default ProjectReportsPage
