import {useContext} from 'react'
import {useForm} from 'react-hook-form'
import {ConfirmationModal} from '../../../../components/ConfirmationModal'
import {SectionForm} from '../../../../components/SectionForm'
import {yupResolver} from '@hookform/resolvers/yup'
import {AddSpentTimeFormSchema, EditSpentTimeFormSchema} from '../../validations'
import {TicketDetailsPageContext} from '../../context'

interface SpentTimeFormProps {
  spent_time_created_at: string
  spent_time: string
  log_description: string
  spent_time_diff: string
}

const SpentTimeModal = ({openModal, onClose, isEdit, logData}: any) => {
  const {updateTicket, isLoadingTicketOperation, isSpentTimeLoading} =
    useContext(TicketDetailsPageContext)
  const {updateSpentTime, isTimeLogOperationLoading} = useContext(TicketDetailsPageContext)

  const defaultValues = {
    spent_time_created_at: isEdit
      ? new Date(logData.spent_time_created_at * 1000).toISOString() // Full date and time for edit
      : new Date().toISOString(), // Full date and time for new entry
    spent_time: logData?.spent_time || '',
    spent_time_diff: logData?.spent_time_diff || '',
    log_description: logData?.log_description || '',
  }

  const {
    control,
    reset,
    handleSubmit,
    setError,
    register,
    formState: {errors, isDirty, dirtyFields},
  } = useForm<any>({
    defaultValues,
    resolver: yupResolver(isEdit ? EditSpentTimeFormSchema : AddSpentTimeFormSchema),
  })

  const handleFormSubmit = (data: SpentTimeFormProps) => {
    if (isEdit) {
      const payload = {
        spent_time_diff: data?.spent_time_diff,
        log_description: data?.log_description,
        spent_time_created_at: new Date(data.spent_time_created_at).toISOString(),
      }
      updateSpentTime(payload, dirtyFields, setError, logData?.id)
    } else {
      const payload = {
        spent_time: data?.spent_time,
        log_description: data?.log_description,
        spent_time_created_at: new Date(data.spent_time_created_at).toISOString(),
      }
      updateTicket(payload, {}, setError)
    }
  }

  const handleCancel = () => {
    reset()
    onClose()
  }

  return (
    <ConfirmationModal
      uniqueID='spent-time-modal'
      show={openModal}
      title={`${isEdit ? 'Edit' : 'Add'} Spent Time`}
      onClose={handleCancel}
      actionName={isEdit ? 'Save' : 'Add'}
      isDataLoading={isSpentTimeLoading}
      onAction={handleSubmit(handleFormSubmit)}
      isDisabled={!isDirty}
      isCloseOnBackdrop={false}
      isOperationLoading={isLoadingTicketOperation || isTimeLogOperationLoading}
      body={
        <SectionForm
          id='create-ticket-status'
          commonClassNames='mb-6'
          commonLabelClass='w-100'
          commonInputClass='col'
          register={register}
          control={control}
          errors={errors}
          inputs={[
            {
              id: 'start-spent_time_created_at',
              label: 'Date',
              required: true,
              isInputDate: true,
              registerKey: 'spent_time_created_at',
            },
            {
              id: 'spent-time',
              label: 'Spent Time',
              required: true,
              registerKey: `${isEdit ? 'spent_time_diff' : 'spent_time'}`,
              isInputTime: true,
              isNormalInput: true,
            },
            {
              id: 'log_description',
              label: 'Description',
              isTextArea: true,
              inputClass: 'resize-none',
              registerKey: 'log_description',
              class: 'col-md-12 mb-7',
              maxLength: 255,
            },
          ]}
        />
      }
    />
  )
}

export default SpentTimeModal
