import {useEffect, useRef, useState} from 'react'
import moment from 'moment'
import $ from 'jquery'
import 'daterangepicker'

interface PredefinedDateRangeProps {
  cb: (start: moment.Moment, end: moment.Moment) => void
  getLbl?: (label: string) => void
  exclude?: string[]
  maxRange?: number
  defaultValue?: string
}

function PredefinedDateRange({
  cb,
  getLbl,
  exclude = [],
  maxRange,
  defaultValue,
}: PredefinedDateRangeProps) {
  const dateRangePickerRef = useRef<HTMLInputElement>(null)
  const [selectedLabel, setSelectedLabel] = useState<string>(defaultValue || 'This Month')

  useEffect(() => {
    const allRanges: {[name: string]: [any, any]} = {
      Today: [moment().toDate(), moment().toDate()],
      Yesterday: [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
      'This Month': [moment().startOf('month').toDate(), moment().toDate()],
      'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
      'Last 30 Days': [moment().subtract(29, 'days').toDate(), moment().toDate()],
      'Last 60 Days': [moment().subtract(59, 'days').toDate(), moment().toDate()],
      'Last 90 Days': [moment().subtract(89, 'days').toDate(), moment().toDate()],
    }

    let start = moment().startOf('month')
    let end = moment()

    if (selectedLabel in allRanges) {
      const [startDate, endDate] = allRanges[selectedLabel]
      start = moment(startDate)
      end = moment(endDate)
    } else if (selectedLabel.includes('to')) {
      const [startStr, endStr] = selectedLabel.split(' to ')
      start = moment(startStr)
      end = moment(endStr)
    }

    const ranges = Object.entries(allRanges).reduce((acc, [key, value]) => {
      if (!exclude.includes(key)) {
        acc[key] = value
      }
      return acc
    }, {} as {[name: string]: [any, any]})

    const options = {
      startDate: start,
      endDate: end,
      maxDate: moment(),
      ranges: ranges,
      minDate: moment().subtract(1, 'year'),
      isInvalidDate: function (date: any): boolean {
        return false
      },
      isCustomDate: function (date: any): string | false | undefined {
        return undefined
      },
      ...(maxRange && {dateLimit: {days: maxRange}}),
    }

    const dateRangePickerElement = $(dateRangePickerRef.current as HTMLInputElement)

    if (dateRangePickerElement && dateRangePickerElement.length && options) {
      dateRangePickerElement.daterangepicker(options, (start: any, end: any, label: any) => {
        const daysDiff = end.diff(start, 'days')

        if (maxRange && daysDiff > maxRange) {
          alert(`Please select a date range of ${maxRange} days or less`)
          dateRangePickerElement.data('daterangepicker')!.setStartDate(moment().startOf('month'))
          dateRangePickerElement.data('daterangepicker')!.setEndDate(moment())
          return
        }

        if (label === 'Custom Range') {
          setSelectedLabel(`${start.format('YYYY-MM-DD')} to ${end.format('YYYY-MM-DD')}`)
          getLbl?.(`${start.format('YYYY-MM-DD')} to ${end.format('YYYY-MM-DD')}`)
          localStorage.setItem(
            'dateRangeLable',
            `${start.format('YYYY-MM-DD')} to ${end.format('YYYY-MM-DD')}`
          )
        } else {
          setSelectedLabel(label)
          getLbl?.(label)
          localStorage.setItem('dateRangeLable', label)
        }
        cb(start, end)
      })
      localStorage.setItem('dateRangeLable', selectedLabel)
      cb(start, end)
    }

    return () => {
      // Clean up any event listeners or subscriptions here
      if (dateRangePickerElement && dateRangePickerElement.length) {
        dateRangePickerElement.daterangepicker()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div className='input-group' ref={dateRangePickerRef} id='kt_daterangepicker_4'>
        <input
          className='form-control form-control-solid'
          placeholder='Pick date range'
          value={selectedLabel}
          readOnly
        />
        <span
          className='input-group-text btn btn-active-light-primary btn-sm btn-icon'
          data-inbox='calendar-show'
        >
          <i className='la la-calendar fs-2'></i>
        </span>
      </div>
    </div>
  )
}

export default PredefinedDateRange
