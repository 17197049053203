/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import {KTSVG} from '../../_metronic/helpers'
import {SideFilterType} from './SideFilterType'
import NoDataFound from '../NoDataFound'

const SideFilter: React.FC<SideFilterType> = ({
  filterData,
  onApply,
  onCancel,
  isDrawerOpen,
  setIsDrawerOpen,
  filterType,
}) => {
  const [selectedFilters, setSelectedFilters] = useState<Record<string, number[]>>({})
  const [initialFilters, setInitialFilters] = useState<Record<string, number[]>>({})
  const [activeFilters, setActiveFilters] = useState<Record<string, boolean>>({})
  const validFilters = filterData.filter((filter: any) => filter.id && Array.isArray(filter.data))

  // Initialize filters
  useEffect(() => {
    const initialSelected: Record<string, number[]> = {}
    const initialActive: Record<string, boolean> = {}

    validFilters.forEach((filter: any) => {
      initialSelected[filter.id] = filter.data
        .filter((item: any) => item.selected)
        .map((item: any) => item.id)
      initialActive[filter.id] = true
    })

    setSelectedFilters(initialSelected)
    setInitialFilters(initialSelected)
    setActiveFilters(initialActive)
  }, [filterData])

  // Handle individual checkbox change
  const handleCheckboxChange = (filterType: string, value: number) => {
    setSelectedFilters((prevState) => {
      const newSelections = [...(prevState[filterType] || [])]
      if (newSelections.includes(value)) {
        newSelections.splice(newSelections.indexOf(value), 1)
      } else {
        newSelections.push(value)
      }
      return {...prevState, [filterType]: newSelections}
    })
  }

  // Handle "Select All" checkbox change
  const handleSelectAllChange = (filterType: string, isChecked: boolean) => {
    setSelectedFilters((prevState) => ({
      ...prevState,
      [filterType]: isChecked
        ? validFilters
            .find((filter: any) => filter.id === filterType)
            ?.data.map((item: any) => item.id) || []
        : [],
    }))
  }

  // Apply filters
  const handleApply = () => {
    const payload = {
      columns: Object.entries(selectedFilters).reduce((acc: any, [key, values]) => {
        acc[key] = values
        return acc
      }, {}),
      type: filterType,
    }
    onApply(payload)
    setIsDrawerOpen(false)
  }

  // Cancel filters and reset to initial values
  const handleCancel = () => {
    setSelectedFilters(initialFilters) // Reset to initial state
    setIsDrawerOpen(false)
    if (onCancel) onCancel()
  }

  // Clear all filters
  const handleClearAllFilters = () => {
    setSelectedFilters({})
  }

  // Clear individual filter
  const handleClearFilter = (filterType: string) => {
    setSelectedFilters((prevState) => ({...prevState, [filterType]: []}))
  }

  // Toggle accordion active state
  const toggleActiveFilter = (filterType: string) => {
    setActiveFilters((prevState) => ({
      ...prevState,
      [filterType]: !prevState[filterType],
    }))
  }

  return (
    <div className={`ad-drawer-section ${isDrawerOpen ? 'ad-drawer-active' : ''}`}>
      {/* Drawer Overlay */}
      <div className='ad-drawer-section-overlay' onClick={handleCancel}></div>

      {/* Drawer Content */}
      <div className='ad-drawer-section-inner'>
        <div className='h-100 px-8'>
          <div className='py-5 border-bottom mb-5 d-flex justify-content-between align-items-center'>
            <div className='d-flex align-items-center'>
              <h4 className='modal-title fw-bolder'>Filters</h4>
              <span
                onClick={handleClearAllFilters}
                className={`cursor-pointer text-primary ms-3 fs-7 ${
                  selectedFilters.assignee?.length ||
                  selectedFilters.priority?.length ||
                  selectedFilters.ticket_status?.length
                    ? ''
                    : 'd-none'
                }`}
              >
                Clear all Filters
              </span>
            </div>
            <div className='cursor-pointer' onClick={handleCancel}>
              <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
            </div>
          </div>

          {/* Filter List */}
          <div className='app-drawer-nav-body'>
            <div className='ad-accordion-section'>
              {validFilters.length > 0 ? (
                validFilters.map((filter: any) => (
                  <div
                    className={`ad-accordion-item mb-5 ${
                      activeFilters[filter.id] ? 'ad-accordion-active' : ''
                    }`}
                    key={filter.id}
                  >
                    <div
                      className='ad-accordion-title cursor-pointer py-5 d-flex justify-content-between'
                      onClick={() => toggleActiveFilter(filter.id)}
                    >
                      <div className='d-flex align-items-center'>
                        <h3 className='fs-4 fw-semibold mb-0'>{filter.label}</h3>
                        <span
                          onClick={(e) => {
                            e.stopPropagation()
                            handleClearFilter(filter.id)
                          }}
                          className={`cursor-pointer text-primary ms-3 fs-7 ${
                            selectedFilters[filter.id]?.length ? '' : 'd-none'
                          }`}
                        >
                          Clear
                        </span>
                      </div>

                      <span className='ad-accordion-icon'>
                        <i className='las la-angle-down'></i>
                      </span>
                    </div>
                    {activeFilters[filter.id] && (
                      <div className='ad-accordion-list'>
                        <div className='border-bottom pb-2 mb-2'>
                          <div className='form-check form-check-custom form-check-sm'>
                            <input
                              type='checkbox'
                              className='form-check-input me-3'
                              id={`${filter.id}-select-all`}
                              checked={selectedFilters[filter.id]?.length === filter.data.length}
                              onChange={(e) => handleSelectAllChange(filter.id, e.target.checked)}
                            />
                            <label htmlFor={`${filter.id}-select-all`}>All</label>
                          </div>
                        </div>
                        {filter.data.map((item: any) => (
                          <div key={item.id} className='mb-3'>
                            <div className='form-check form-check-custom form-check-sm'>
                              <input
                                type='checkbox'
                                className='form-check-input me-3'
                                id={`${filter.id}-${item.id}`}
                                value={item.id}
                                checked={selectedFilters[filter.id]?.includes(item.id)}
                                onChange={() => handleCheckboxChange(filter.id, item.id)}
                              />
                              <label htmlFor={`${filter.id}-${item.id}`}>{item.label}</label>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <NoDataFound message='No filters available.' colspan={0} />
              )}
            </div>
          </div>

          {/* Footer */}
          <div className='d-flex align-items-center justify-content-end border-top mt-5 py-5'>
            <button type='button' className='btn btn-outline' onClick={handleCancel}>
              Cancel
            </button>
            <button
              type='button'
              className='btn btn-primary ms-3'
              onClick={handleApply}
              disabled={!Boolean(validFilters.length)}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideFilter
