import useApi from '../../../services/useApi'
import {useContext, useState} from 'react'
import {TicketDetailsContext} from '../context'

const useGetTicketSpentTimeHistoryOperation = () => {
  const Api = useApi()
  const {ticketId} = useContext(TicketDetailsContext)
  const [isLoading, setLoading] = useState(false)

  async function updateSpentTime(data: any, dirtyFields: any, setError: Function, timeLogId: any) {
    return await Api.patch(`/agile/projects/${ticketId}/time-log/${timeLogId}`, data, {
      dirtyFields: dirtyFields,
      setLoading: setLoading,
      refetch: ['project-ticket-details', 'ticket-activities-history', 'ticket-spentTime-time-log'],
      setUseFormError: setError,
    })
  }

  async function deleteSpentTime(timeLogId: any) {
    return await Api.delete(
      `/agile/projects/${ticketId}/time-log/${timeLogId}`,
      {},
      {
        setLoading: setLoading,
        successMessage: 'Timelog deleted successfully',
        refetch: [
          'project-ticket-details',
          'ticket-activities-history',
          'ticket-spentTime-time-log',
        ],
      }
    )
  }

  return {
    updateSpentTime,
    deleteSpentTime,
    isLoading,
  }
}

export default useGetTicketSpentTimeHistoryOperation
