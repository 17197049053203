/* eslint-disable */
import {useContext, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import AddBoardColumnModal from '../../modals/AddBoardColumnModal'
import DndTable from '../../../../../components/dndTable/DndTable'
import {BoardSettingsPageContext, ProjectDetailsContext} from '../../../context'
import ColumnsTableRow from './ColumnsTableRow'
import {boardColumnsTableColumns, defaultBoardColumnStatusMappingValues} from '../../../utils'

export default function ColumnsTable() {
  const {
    updateColumnTableSorting,
    boardColumnsList,
    columnMappingDropdownList,
    isLoadingBoardColumnsList,
    hasBoardSettingsPermission,
  } = useContext(BoardSettingsPageContext)
  const form = useForm<any>()
  const {projectMappingDetails} = useContext(ProjectDetailsContext)
  const [showModal, setShowModal] = useState(false)
  const [isDefaultValueAssigned, setIsDefaultValueAssigned] = useState(false)

  // assign default values to form
  useEffect(() => {
    if (boardColumnsList?.length > 0 && !isDefaultValueAssigned) {
      form.reset(defaultBoardColumnStatusMappingValues(boardColumnsList))
      setIsDefaultValueAssigned(true)
    }
    if (isLoadingBoardColumnsList) {
      setIsDefaultValueAssigned(false)
    }
  }, [boardColumnsList, isDefaultValueAssigned, isLoadingBoardColumnsList])

  // get status mapping dropdown list
  const statusMappingDropdownList = columnMappingDropdownList?.map((item: any) => ({
    ...item,
    disabled: Object.values(form.watch())?.some((column: any) => column === item.label),
  }))

  // check if status mapping is valid
  const isValidForStatusMapping =
    projectMappingDetails?.projectType === 'custom' && statusMappingDropdownList?.length > 0

  // update column sorting
  const handleUpdatedTableRows = (tableData: any) => {
    updateColumnTableSorting({column_ids: tableData.map((item: any) => item.columnId)})
  }

  return (
    <div className='position-relative'>
      <div className='d-flex justify-content-between align-items-center mb-7'>
        <h4 className='fs-3 fw-bolder mb-0'>Columns</h4>
        {hasBoardSettingsPermission && (
          <div className='d-flex justify-content-end'>
            {!isLoadingBoardColumnsList && (
              <button type='button' onClick={() => setShowModal(true)} className='btn btn-primary'>
                <i className='fa-solid fa-plus'></i>Add Column
              </button>
            )}
            {showModal && (
              <AddBoardColumnModal
                show={showModal}
                isEdit={false}
                onClose={() => setShowModal(false)}
              />
            )}
          </div>
        )}
      </div>

      <DndTable
        id='board-columns-table'
        data={boardColumnsList}
        columns={boardColumnsTableColumns(isValidForStatusMapping)}
        uniqueId='columnId'
        isLoading={isLoadingBoardColumnsList}
        onChange={handleUpdatedTableRows}
        hasWritePermission={hasBoardSettingsPermission}
        TableRow={ColumnsTableRow}
        tableRowProps={{
          statusMappingDropdownList,
          isValidForStatusMapping,
          form,
        }}
        noDataText={
          <p>
            No columns have been added yet. <br /> Please click on 'Add Column' button to add
            columns.
          </p>
        }
      />
    </div>
  )
}
