/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react'
import useFilters from '../../../../hook/useFilters'
import useApi from '../../../../services/useApi'
import {useGetProjectsDropdownList} from '../useGetProjectsDropdownList'

const initialFilters = {
  page: 1,
  limit: 30,
  sort_by: 'project_name/1',
  project_id: '',
}

const useGetTasksReportsData = () => {
  const Api = useApi()
  const {isLoading, projectsDropdownList} = useGetProjectsDropdownList()

  const {filters, onSearch, onSortingChange, searchValue, onPageChange, setMultipleFilters} =
    useFilters(initialFilters, {
      sortType: 'number',
    })

  const {
    data: response,
    isFetching,
    refetch,
  } = Api.useGetQuery(
    `/agile/projects/task-report`,
    {
      queryId: 'task-report',
      filters: filters,
    },
    {
      enabled: !isLoading && filters.project_id !== '',
    }
  )

  const onProjectChange = (id: any) => {
    setMultipleFilters({
      project_id: id,
      page: 1,
    })
  }

  useEffect(() => {
    onProjectChange(projectsDropdownList.map((item: {id: string}) => item.id).join(','))
  }, [isLoading])

  return {
    tasksReportsData: response?.data?.task_report?.data || [],
    pagination: response?.data?.task_report?.meta?.pagination || {},
    filtersData: response?.data?.filters || [],
    filters,
    isLoading: isFetching || isLoading,
    onSortingChange,
    onProjectChange,
    onPageChange,
    onSearch,
    searchValue,
    refetch
  }
}

export default useGetTasksReportsData
