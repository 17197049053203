import {useContext} from 'react'
import {ReachSelect} from '../../../../../components/reachSelect'
import ColumnActionsOverlay from '../../overlays/ColumnActionsOverlay'
import {BoardSettingsPageContext} from '../../../context'
import {getBadgeColor} from '../../../../../utils/badge'

const ColumnsTableRow = ({
  dndSettings,
  row,
  hasWritePermission,
  dndImage,
  statusMappingDropdownList,
  isValidForStatusMapping,
  form,
}: any) => {
  const {
    isLoadingColumnMappingDropdownList,
    mapColumnStatus,
    columnOperationId,
    isLoadingColumnOperation,
  } = useContext(BoardSettingsPageContext)

  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: {errors},
  } = form

  const handleMapProjectTableStatus = () => {
    const columnId = String(row?.columnId)
    const columnValue = watch(columnId)
    mapColumnStatus(columnValue, columnId, setError)
  }
  
  const isDropdownLoading =
    isLoadingColumnMappingDropdownList ||
    (isLoadingColumnOperation && columnOperationId === String(row?.columnId))

  const isDropdownDisabled =
    isLoadingColumnMappingDropdownList || isLoadingColumnOperation || !hasWritePermission

  return (
    <tr {...dndSettings}>
      <td>
        {dndImage}
        {row?.columnName}
        {row?.isResolved && (
          <span className={`ms-2 badge badge-lg badge-light-primary`}>
            {' '}
            <div className='align-items-center'>{'Resolved'}</div>
          </span>
        )}
      </td>
      <td>{row?.description}</td>
      {isValidForStatusMapping && (
        <td>
          <ReachSelect
            id={row?.columnId}
            control={control}
            registerKey={`${row?.columnId}`}
            options={statusMappingDropdownList}
            labelKey='label'
            valueKey='label'
            disabledKey='disabled'
            isNullable={true}
            isLoading={isDropdownLoading}
            disabled={isDropdownDisabled}
            onChange={handleSubmit(handleMapProjectTableStatus)}
            error={errors?.[row?.columnId]}
          />
        </td>
      )}
      <td>
        <div className='text-center'>{row?.cards}</div>
      </td>
      <td>
        <div className='text-center'>
          <span className={`badge ${getBadgeColor(row?.visibility, 'light')} badge-lg`}>
            <div className='align-items-center'>{row?.visibility}</div>
          </span>
        </div>
      </td>
      {hasWritePermission && (
        <td>
          {isLoadingColumnOperation && columnOperationId === row?.columnId ? (
            <div className='d-flex justify-content-center'>
              <span className='spinner-border spinner-border-sm align-middle'></span>
            </div>
          ) : (
            <ColumnActionsOverlay columnId={row?.columnId} columnData={row} />
          )}
        </td>
      )}
    </tr>
  )
}

export default ColumnsTableRow
