import useApi from '../../../../services/useApi'
import useFilters from '../../../../hook/useFilters'
import { ProjectDetailsContext } from '../../context'
import { useContext } from 'react'

export const useGetAgileBoardTicketList = () => {
  const Api = useApi()
  const {projectId} = useContext(ProjectDetailsContext)

  const initialFilters = {
    page: 1,
    limit: 30,
    sort_by: 'column_name/1',
    search: true,
  }

  const {filters, onSearch, onPageChange, onSortingChange} = useFilters(initialFilters, {
    sortType: 'number',
  })

  const {
    data: response,
    isFetching,
    refetch,
  } = Api.useGetQuery(`/agile/projects/${projectId}/cards/listview`, {
    queryId: 'agile-board-ticket-list',
    filters: filters,
  })

  return {
    agileBoardTicketList: response?.data?.cards_report?.data || [],
    pagination: response?.data?.cards_report?.meta?.pagination || {},
    filtersData: response?.data?.filters || [],
    isLoading: isFetching,
    filters,
    onSearch,
    onPageChange,
    onSortingChange,
    refetch,
  }
}
