import AgileBoard from './layouts/AgileBoard'
import useMeta from '../../hook/useMeta'
import BoardSetting from './layouts/BoardSetting'
import Members from './layouts/Members'
import {PageLink} from '../../_metronic/layout/core'
import {ProjectDetailsContext} from './context'
import useGetProjectDetails from './hooks/useGetProjectDetails'
import ListView from './layouts/ListView'
// import GanttChart from './layouts/GanttChart'
import Navigation from '../../components/Navigation/Navigation'
import {useParams} from 'react-router-dom'
import usePermission from '../../hook/usePermission'

function ProjectPage() {
  useMeta('Project')

  const {hasPermission} = usePermission()
  const {projectId} = useParams()
  const {projectDetails, projectMappingDetails, isLoading, isFetching} = useGetProjectDetails()
  const {name, status} = projectDetails
  const hasProjectPermission =
    hasPermission('projects_all projects', 'write') && status ? status !== 'archived' : false

  const analyticsBreadCrumbs: Array<PageLink> = [
    {
      title: 'Projects',
      path: `/projects/all-projects`,
      isSeparator: false,
      isActive: false,
    },
  ]

  const contextValue = {
    projectId,
    projectName: name,
    projectStatus: status,
    projectDetails,
    projectMappingDetails: projectMappingDetails,
    hasProjectPermission,
    isLoading: isFetching,
  }

  return (
    <ProjectDetailsContext.Provider value={contextValue}>
      <Navigation
        baseUrl={`/projects/all-projects/${projectId}`}
        pageTitle={name}
        breadcrumbs={analyticsBreadCrumbs}
        isLoading={isLoading}
        navigationData={[
          {
            key: 'agile-board',
            label: 'Agile Board',
            component: <AgileBoard />,
          },
          {
            key: 'list-view',
            label: 'List View',
            component: <ListView />,
          },
          {
            key: 'members',
            label: 'Members',
            component: <Members />,
          },
          // {
          //   key: 'gantt-chart',
          //   label: 'Gantt Chart',
          //   component: <GanttChart />,
          // },
          {
            key: 'board-setting',
            label: 'Board Setting',
            component: <BoardSetting />,
          },
        ]}
      />
    </ProjectDetailsContext.Provider>
  )
}

export default ProjectPage
