import {Link} from 'react-router-dom'
import {OverlayDropdowns} from '../../../components/OverlayDropdowns'
import {ReachSelect} from '../../../../../components/reachSelect'
import InputDate from '../../../../../components/InputDate/InputDate'
import {getBadgeColor} from '../../../../../utils/badge'
import {useContext, useEffect} from 'react'
import {ListViewPageContext} from '../../../context'
import {useForm} from 'react-hook-form'
import {KTSVG} from '../../../../../_metronic/helpers'
import {defaultListViewTicketValues} from '../../../utils'

const ListViewTableRow = ({row}: any) => {
  const {
    hasListViewPermission,
    projectId,
    projectMembers,
    isLoadingProjectMembers,
    updateTicket,
    isLoadingAgileBoardTicketList,
  } = useContext(ListViewPageContext)

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setError,
    formState: {dirtyFields},
  } = useForm({
    defaultValues: defaultListViewTicketValues(row),
  })

  const onSubmit = () => {
    updateTicket(watch(), row?.id, dirtyFields, setError)
    document.body.click()
  }

  useEffect(() => {
    reset(defaultListViewTicketValues(row))
  }, [row, reset, isLoadingAgileBoardTicketList])

  return (
    <tr>
      <td>
        <Link to={`/projects/all-projects/${projectId}/ticket/${row.id}/${row.ticketIdentifier}`}>
          {row.ticketIdentifier}
          <span className='text-dark'> - {row.ticketName}</span>
        </Link>
      </td>
      <td>{row.module}</td>
      <td>{row.stage}</td>
      <td>
        <OverlayDropdowns
          hasValue={!!row?.assignee}
          disabled={!hasListViewPermission}
          triggerIcon={
            row?.assignee ? row?.assignee : <KTSVG path='/media/ad-theme-icons/user-assignee.svg' />
          }
        >
          <ReachSelect
            control={control}
            registerKey='assignedTo'
            id='ticket-assignee-dropdown'
            options={projectMembers}
            labelKey='name'
            valueKey='username'
            isLoading={isLoadingProjectMembers}
            placeholderValue='Select Assignee'
            menuIsOpen={true}
            autoFocus={true}
            isFloatingMenu={false}
            menuShouldScrollIntoView={false}
            className='min-w-200px mw-200px text-capitalize fs-7 fw-semibold'
            onChange={handleSubmit(onSubmit)}
            disableDropdownIndicator={true}
          />
        </OverlayDropdowns>
      </td>
      <td>
        <OverlayDropdowns
          hasValue={!!row?.dueDate}
          disabled={!hasListViewPermission}
          triggerIcon={
            row?.dueDate ? (
              <div className='fs-6 text-d fw-normal'>{row?.dueDate}</div>
            ) : (
              <i className='fa-regular fs-4 fa-calendar-minus text-gray-700' />
            )
          }
          className='bg-body'
        >
          <InputDate
            control={control}
            registerKey='dueDate'
            id='ticket-estimate-date-picker'
            menuClassName='position-static shadow-none border-0'
            onOk={handleSubmit(onSubmit)}
            onClean={handleSubmit(onSubmit)}
            floatingElement={false}
            autoFocus={true}
          />
        </OverlayDropdowns>
      </td>
      <td>
        <span className={`badge ${getBadgeColor(row.priority, 'light')} badge-lg`}>
          <div className='align-items-center'>{row.priority}</div>
        </span>
      </td>
      <td>{row.createdDate}</td>
      <td>{row.updatedDate}</td>
    </tr>
  )
}

export default ListViewTableRow
