import useApi from '../../../services/useApi'
import useFilters from '../../../hook/useFilters'
import {useContext, useState} from 'react'
import {TicketDetailsContext} from '../context'

const useGetTicketSpentTimeHistory = () => {
  const Api = useApi()
  const {ticketId} = useContext(TicketDetailsContext)
  const [spentTime, setSpentTime] = useState<any[]>([])

  const initialFilters = {
    page: 1,
    limit: 20,
  }

  const {filters, onPageChange} = useFilters(initialFilters)

  const {data: response, isFetching} = Api.useGetQuery(
    `/agile/projects/${ticketId}/time-log`,
    {
      queryId: 'ticket-spentTime-time-log',
      filters: filters,
    },
    {
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
      onSuccess: (response: any) => {
        if (filters.page === 1) {
          setSpentTime(response?.data || [])
        } else {
          setSpentTime((prev: any) => [...prev, ...(response?.data?.data || [])])
        }
      },
    }
  )

  return {
    ticketSpentTimeHistory: spentTime,
    SpentTimePagination: response?.meta,
    isSpentTimeLoading: isFetching,
    onLoadMoreSpentTime: onPageChange,
    currentPage: filters.page,
  }
}

export default useGetTicketSpentTimeHistory
