import {dataToUsers} from '../../utils'
import Initials from '../../../../components/Initials'
import {getBadgeColor} from '../../../../utils/badge'
import usePermission from '../../../../hook/usePermission'
import OverlayModal from '../modals/OverlayModal'

const TableRow = ({row}: any) => {
  const customer = dataToUsers(row)
  const {hasPermission} = usePermission()

  return (
    <tr>
      <td>
        <div className='d-flex align-items-center flex-grow-1'>
          <div className='symbol symbol-circle symbol-40px me-3'>
            <Initials text={customer['nameFirstLetter']} />
          </div>
          <div className='d-flex flex-column'>
            <div className='align-items-center fw-bold'>{customer['name']}</div>
            <div className='align-items-center'>{customer['username']}</div>
          </div>
        </div>
      </td>
      <td>
        <span className='text-dark text-hover-primary fs-6'>
          <div className='align-items-center'>{customer['role']}</div>
        </span>
      </td>
      <td>
        <span className='text-dark text-hover-primary fs-6'>
          <div className='align-items-center'>{customer['lastLogin']}</div>
        </span>
      </td>
      <td>
        <span className={`badge ${getBadgeColor(customer['status'], 'light')} badge-lg`}>
          <div className='align-items-center'>{customer['status']}</div>
        </span>
      </td>

      {hasPermission('settings_users', 'write') ? (
        <td>
          <div>
            <OverlayModal name={customer['username']} id={customer['id']} status={customer['status']}/>
          </div>
        </td>
      ) : null}
    </tr>
  )
}

export default TableRow
