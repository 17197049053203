import {useContext} from 'react'
import {MembersReportPageContext} from '../../context'
import {DynamicTable} from '../../../../components/DynamicTable'
import {MembersReportTableColumns} from '../../utils'
import MembersReportTableRow from './MembersReportTableRow'
import { dataToMembersReportRow } from '../../parsings'

export default function MembersReportTable() {
  const {isLoading, membersReportData, onSortingChange, searchValue, filters} =
    useContext(MembersReportPageContext)

  return (
    <DynamicTable
      id={'members-report'}
      data={membersReportData}
      sortableColumns={MembersReportTableColumns}
      TableRow={MembersReportTableRow}
      permissionPath={'projects_settings'}
      loading={isLoading}
      filters={filters}
      tableClass="table-row-dashed table-row-gray-300 align-middle mb-15 gs-0 gy-3"
      onSortingChange={onSortingChange}
      searchValue={searchValue}
      rowParsingFunction={dataToMembersReportRow}
    />
  )
}
