import {createContext} from 'react'

export const ProjectDetailsContext = createContext({
  projectId: null as any,
  projectName: '',
  projectStatus: '',
  projectDetails: {} as any,
  projectMappingDetails: {} as any,
  hasProjectPermission: false,
  isLoading: false,
})

export const AgileBoardPageContext = createContext({
  hasBoardPermission: false,

  projectId: null as any,
  projectName: '',
  projectStatus: '',

  agileBoardStructure: [],
  isLoadingBoardStructure: false,

  agileBoardData: [],
  filtersData: [],
  refetchAgileBoardData: () => {},
  isLoadingAgileBoardData: false,
  onSearch: (e: string) => {},

  projectMembers: [],
  isLoadingProjectMembers: false,

  projectKanbanStages: [],
  isLoadingProjectKanbanStages: false,

  projectModules: [],
  isLoadingProjectModules: false,

  projectStatuses: [],
  isLoadingProjectStatuses: false,

  projectPriorities: [],
  isLoadingProjectPriorities: false,

  createTicket: (moduleId: string, data: any, setError: Function) => {},
  updateTicket: (data: any, ticketId: number, dirtyFields: any, setError: Function) => {},
  isLoadingTicketOperation: false,

  updateAgileBoard: (updatedTaskDetails: any, taskId: string) => {},
  isUpdatingAgileBoard: false,
})

export const ListViewPageContext = createContext({
  hasListViewPermission: false,

  projectId: null as any,
  projectName: '',
  projectStatus: '',

  agileBoardTicketList: [],
  isLoadingAgileBoardTicketList: false,
  filters: {},
  onSearch: (e: string) => {},
  onPageChange: (page: number) => {},
  onSortingChange: (sortBy: string, sortDirection: any) => {},
  filtersData: [],
  refetchAgileBoardTicketList: () => {},

  isLoadingAgileBoardData: false,

  projectMembers: [],
  isLoadingProjectMembers: false,

  projectKanbanStages: [],
  isLoadingProjectKanbanStages: false,

  projectModules: [],
  isLoadingProjectModules: false,

  projectStatuses: [],
  isLoadingProjectStatuses: false,

  projectPriorities: [],
  isLoadingProjectPriorities: false,

  createTicket: (moduleId: string, data: any, setError: Function) => {},
  updateTicket: (data: any, ticketId: number, dirtyFields: any, setError: Function) => {},
  isLoadingTicketOperation: false,
})

export const ProjectMembersPageContext = createContext({
  hasMembersPermission: false,

  projectId: null as any,
  projectName: '',
  projectStatus: '',

  projectMembersList: [],
  isLoadingProjectMembersList: false,
  filters: {},
  onSearch: (e: string) => {},
  onSortingChange: (sortBy: string) => {},

  membersList: [],
  isLoadingMembersList: false,

  addProjectMembers: (memberDetails: any) => {},
  addProjectTeams: (teamIds: any) => {},
  removeProjectMember: (memberID: number) => {},
  transferProjectOwnership: (payload: any) => {},
  isLoadingOperation: false,

  projectMembersDropdownList: [],
  isLoadingProjectMembersDropdownList: false,

  isLoadingProjectsTeamsList: false,
  ProjectsTeamsList: [],
})

export const BoardSettingsPageContext = createContext({
  hasBoardSettingsPermission: false,

  projectId: null as any,
  projectName: '',
  projectStatus: '',

  boardColumnsList: [],
  isLoadingBoardColumnsList: false,

  columnMappingDropdownList: [],
  isLoadingColumnMappingDropdownList: false,

  boardModulesList: [],
  isLoadingBoardModulesList: false,

  boardCustomFieldsList: [],
  isLoadingBoardCustomFieldsList: false,

  customFieldMappingDropdownList: [],
  isLoadingCustomFieldMappingDropdownList: false,

  globalCustomFieldsList: [],
  isLoadingGlobalCustomFieldsList: false,

  createColumn: (data: any, setError: Function) => {},
  updateColumn: (moduleId: string, data: any, dirtyFields?: any, setError?: Function) => {},
  updateColumnTableSorting: (columnTableRowOrder: any) => {},
  deleteColumn: (moduleId: string) => {},
  mapColumnStatus: (selectedOption: any, columnId: string, setError: Function) => {},
  columnOperationId: null,
  isLoadingColumnOperation: false,

  createModule: (data: any, setError: Function) => {},
  updateModule: (moduleId: string, data: any, dirtyFields?: any, setError?: Function) => {},
  updateModuleTableSorting: (moduleTableRowOrder: any) => {},
  deleteModule: (moduleId: string) => {},
  moduleOperationId: null,
  isLoadingModulesOperation: false,

  createCustomField: (customFieldDetails: any, setError: Function) => {},
  updateCustomField: (
    cFieldId: string,
    customFieldDetails: any,
    dirtyFields?: any,
    setError?: Function
  ) => {},
  updateCustomFieldTableSorting: (customFieldsTableRowOrder: any) => {},
  deleteCustomField: (cFieldId: string) => {},
  mapCustomFieldStatus: (selectedOption: any, cFieldId: string, setError: Function) => {},
  customFieldOperationId: null,
  isLoadingCustomFieldOperation: false,

  projectTablesDropdownList: [],
  isLoadingProjectTablesDropdownList: false,

  tableColumnsDropdownList: [],
  isLoadingTableColumnsDropdownList: false,

  projectMembers: [],
  isLoadingProjectMembers: false,

  updateProjectMapping: (payload: any) => {},
  isLoadingProjectMappingOperations: false,
})
