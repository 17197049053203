/* eslint-disable */
import {useContext, useEffect, useState} from 'react'
import DndTable from '../../../../../components/dndTable/DndTable'
import AddBoardCustomFieldsModal from '../../modals/AddBoardCustomFieldsModal'
import {BoardSettingsPageContext, ProjectDetailsContext} from '../../../context'
import CustomFIeldsTableRow from './CustomFIeldsTableRow'
import {boardCustomFieldsColumns, defaultBoardCustomFieldStatusMappingValues} from '../../../utils'
import {useForm} from 'react-hook-form'

export default function CustomFieldsTable() {
  const {
    updateCustomFieldTableSorting,
    boardCustomFieldsList,
    isLoadingBoardCustomFieldsList,
    hasBoardSettingsPermission,
    customFieldMappingDropdownList,
  } = useContext(BoardSettingsPageContext)
  const {projectMappingDetails} = useContext(ProjectDetailsContext)
  const form = useForm<any>()
  const [showModal, setShowModal] = useState(false)
  const [isDefaultValueAssigned, setIsDefaultValueAssigned] = useState(false)

  // assign default values to form
  useEffect(() => {
    if (boardCustomFieldsList?.length > 0 && !isDefaultValueAssigned) {
      form.reset(defaultBoardCustomFieldStatusMappingValues(boardCustomFieldsList))
      setIsDefaultValueAssigned(true)
    }
    if (isLoadingBoardCustomFieldsList) {
      setIsDefaultValueAssigned(false)
    }
  }, [boardCustomFieldsList, isDefaultValueAssigned, isLoadingBoardCustomFieldsList])

  // get status mapping dropdown list
  const statusMappingDropdownList = customFieldMappingDropdownList?.map((item: any) => ({
    ...item,
    disabled: Object.values(form.watch())?.some((column: any) => column === item.column_key),
  }))

  // check if status mapping is valid
  const isValidForStatusMapping =
    projectMappingDetails?.projectType === 'custom' && statusMappingDropdownList?.length > 0

  // update custom field sorting
  const handleUpdatedTableRows = (tableData: any) => {
    updateCustomFieldTableSorting({project_custom_field_ids: tableData.map((item: any) => item.id)})
  }

  return (
    <div className='position-relative'>
      <div className='d-flex justify-content-between align-items-center mb-7'>
        <h4 className='fs-3 fw-bolder mb-0'>Custom Fields</h4>
        {hasBoardSettingsPermission && (
          <div className='d-flex justify-content-end'>
            {!isLoadingBoardCustomFieldsList && (
              <button type='button' onClick={() => setShowModal(true)} className='btn btn-primary'>
                <i className='fa-solid fa-plus'></i>Add Custom Field
              </button>
            )}
            {showModal && (
              <AddBoardCustomFieldsModal
                show={showModal}
                isEdit={false}
                onClose={() => setShowModal(false)}
              />
            )}
          </div>
        )}
      </div>

      <DndTable
        id='board-custom-fields-table'
        data={boardCustomFieldsList}
        columns={boardCustomFieldsColumns(isValidForStatusMapping)}
        uniqueId='id'
        isLoading={isLoadingBoardCustomFieldsList}
        onChange={handleUpdatedTableRows}
        hasWritePermission={hasBoardSettingsPermission}
        TableRow={CustomFIeldsTableRow}
        tableRowProps={{statusMappingDropdownList, isValidForStatusMapping, form}}
        noDataText={
          <p>
            No custom fields have been added yet. <br /> Please click on 'Add Custom Field' button
            to add custom fields.
          </p>
        }
      />
    </div>
  )
}
