/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react'
import useFilters from '../../../../hook/useFilters'
import useApi from '../../../../services/useApi'
import {useGetProjectsDropdownList} from '../useGetProjectsDropdownList'

const initialFilters = {
  project_id: '',
  start_date: true,
  end_date: true,
  sort_by: 'customer_name/-1',
}

const useGetTimeSheetData = () => {
  const Api = useApi()
  const {isLoading, projectsDropdownList} = useGetProjectsDropdownList()

  const {filters, onSearch, onSortingChange, onDateChange, searchValue, setMultipleFilters} =
    useFilters(initialFilters, {
      sortType: 'number',
    })

  const {data: response, isFetching,refetch} = Api.useGetQuery(
    `/agile/projects/timesheet`,
    {
      queryId: 'project-report-time-sheet',
      filters: filters,
    },
    {
      enabled: !isLoading && filters.project_id !== '',
    }
  )

  const onProjectChange = (id: any) => {
    setMultipleFilters({
      project_id: id,
    })
  }

  useEffect(() => {
    onProjectChange(projectsDropdownList.map((item: {id: string}) => item.id).join(','))
  }, [isLoading])

  return {
    timeSheetData: response?.data?.customers || [],
    timeSheetMeta: response?.data?.meta,
    filtersData: response?.data?.filters || [],
    filters,
    isLoading: isFetching || isLoading,
    onSortingChange,
    onSearch,
    searchValue,
    onProjectChange,
    onDateChange,
    refetch
  }
}

export default useGetTimeSheetData
