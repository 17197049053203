import {convertKeysToSnakeCase, isEmpty} from '../../../utils/common'

export const defaultProjectTicketValues = {
  currentColumnId: 0,
  moduleId: 0,
  status: 0,
  priority: 0,
  title: '',
  description: null,
  assignedTo: '',
  spentTime: '',
  estimation: '',
  dueDate: null,
  startDate: null,
}

export const setDefaultTicketDetails = (ticketDetails: any, reset: any) => {
  if (isEmpty(ticketDetails)) return
  return reset({
    assignedTo: ticketDetails?.assignee,
    currentColumnId: ticketDetails?.columnID,
    moduleId: ticketDetails?.moduleID,
    status: ticketDetails?.status,
    priority: ticketDetails?.priority,
    title: ticketDetails?.title,
    description: ticketDetails?.description,
    spentTime: ticketDetails?.spent_time,
    estimation: ticketDetails?.estimation,
    dueDate: ticketDetails?.due_date,
    startDate: ticketDetails?.start_date,
  })
}

export const getUpdatedData = (dirtyFields: any, formData: any) => {
  const updatedData = Object.keys(dirtyFields).reduce((acc: any, key) => {
    acc[key] = formData[key]
    return acc
  }, {})
  return convertKeysToSnakeCase(updatedData)
}

export const defaultCustomFieldsValues = (customFormFields: any) => {
  return customFormFields.reduce((acc: any, field: any) => {
    if (!field.isLabelOnly) {
      acc[field.fieldId] = {
        [field.valueType]: field.defaultValues,
      }
    }
    return acc
  }, {})
}

export const dataToFormFields = (customFields: any) => {
  if (isEmpty(customFields)) return []

  return [
    {
      id: 'line-break',
      isLabelOnly: true,
      label: 'Custom Fields',
      labelClass: 'form-label w-100 fw-bolder fs-3 mb-8 pb-2 border-bottom mt-8',
    },
    ...customFields.map((customField: any) => {
      const field: any = {
        id: `custom-field-${customField.id}`,
        fieldId: customField?.id,
        label: customField.name,
      }

      if (customField.datatype === 'text') {
        field.registerKey = `${customField.id}[strValue]`
        field.valueType = 'strValue'
        field.defaultValues = customField.str_value || ''
        field.maxLength = 255
      }

      if (customField.datatype === 'select') {
        field.isSelect = true
        field.registerKey = `${customField.id}[selectValue]`
        field.valueType = 'selectValue'
        field.defaultValues = customField?.str_value || ''
        field.placeholder = 'Select an option'
        field.isNullable = true
        field.options = customField?.group_values
          ?.split(',')
          .map((val: any) => ({label: val.trim(), value: val.trim()}))
      }

      if (customField.datatype === 'multi select') {
        field.isMultiSelect = true
        field.registerKey = `${customField.id}[multiSelectValue]`
        field.valueType = 'multiSelectValue'
        field.defaultValues = customField?.str_value?.split(', ') || []
        field.inputClass = 'w-100'
        field.placeholder = 'Select an option'
        field.options = customField?.group_values
          ?.split(',')
          .map((val: any) => ({label: val.trim(), value: val.trim()}))
      }

      if (customField.datatype === 'number') {
        field.isNumberInput = true
        field.isFloat = true
        field.registerKey = `${customField.id}[numberValue]`
        field.valueType = 'numberValue'
        field.defaultValues = customField.number_value || null
        field.maxLength = 255
      }

      if (customField.datatype === 'date') {
        field.isInputDate = true
        field.registerKey = `${customField.id}[dateValue]`
        field.valueType = 'dateValue'
        field.defaultValues = customField.str_value || null
        field.isNullable = true
      }
      return field
    }),
  ]
}

export const ticketTitleTextareaHeight = (textareaRef: any) => {
  if (textareaRef.current) {
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
  }
}

export const groupedHistoryByUser = (ticketActivitiesHistory: any[]) => {
  return ticketActivitiesHistory.reduce((acc: any[], activity: any, index: number) => {
    const prevActivity: any = ticketActivitiesHistory[index - 1]
    const isNewGroup = !prevActivity || prevActivity.created_by !== activity.created_by

    if (isNewGroup) {
      acc.push([activity])
    } else {
      acc[acc.length - 1].push(activity)
    }
    return acc
  }, [] as any[][])
}

export const formattedActivities = (activity: any) => {
  const formatComment = (input: string) =>
    input.replace(/<p>(.*?)#.*?<\/p>/, (_, beforeHash) => `@${beforeHash.trim()}`)

  switch (activity.field_name) {
    case 'description':
      return 'Description changed.'

    case 'comment':
      const oldComment = formatComment(activity.old_value)
      const newComment = formatComment(activity.new_value)

      return activity.old_value === '' && activity.new_value !== ''
        ? `Comment added: "${newComment}"`
        : activity.new_value === '' && activity.old_value !== ''
        ? `Comment deleted: "${oldComment}"`
        : `Comment edited: "${oldComment}" → "${newComment}"`

    default:
      return `${activity.field_lable}: ${activity.old_value || 'None'} → ${
        activity.new_value || 'None'
      }`
  }
}


export const getChangedFields = (newData: any, oldData: any) => {
  return Object.keys(newData).reduce((acc: any, key) => {
    if (newData[key] !== oldData[key]) {
      acc[key] = newData[key]
    }
    return acc
  }, {})
}
