import {useContext, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import MultiSelect from '../../../../components/MultiSelect/MultiSelect'
import {dataToSelectOptions} from '../../../../utils/common'
import {TimeSheetReportPageContext} from '../../context'
import {SideFilter} from '../../../../components/SideFilter'
import useSaveFilter from '../../hooks/useSaveFilter'
import PredefinedDateRange from '../../components/PreDefinedDateRange'

const TimeSheetHeader = () => {
  const {
    projectsDropdownList,
    isLoadingProjectDropdownList,
    onProjectChange,
    onDateChange,
    filtersData,
    refetch,
    isLoadingTimeSheetData,
  } = useContext(TimeSheetReportPageContext)
  const options = dataToSelectOptions(projectsDropdownList, 'name', 'id')
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const {isOperationLoading, onSaveFilter} = useSaveFilter(refetch)

  return (
    <div className='d-flex justify-content-between mb-10'>
      <div>
        <MultiSelect
          id='product-status'
          className='w-250px'
          options={options}
          isLoading={isLoadingProjectDropdownList}
          defaultSelectedAll={true}
          allValuesSelectedText={'Project: All'}
          handleSelected={(values: any) => {
            const extractedValues = values.map((item: {value: string}) => item.value).join(',')
            onProjectChange(extractedValues)
          }}
        />
      </div>
      <div className='d-flex align-items-center gap-5'>
        <PredefinedDateRange
          cb={onDateChange}
          exclude={['Current Year', 'Today', 'Yesterday', 'Last 7 Days']}
          maxRange={90}
          defaultValue={'Last 30 Days'}
        />
        <div>
          <button
            className='btn btn-outline px-6'
            id='kt_drawer_column_setting_toggle'
            onClick={() => setIsDrawerOpen(true)}
            disabled={isOperationLoading || isLoadingTimeSheetData}
          >
            <KTSVG
              path='/media/ad-theme-icons/filter-icon.svg'
              className='svg-icon-5 svg-icon-gray-500 me-1'
            />
            Filters
          </button>
          <SideFilter
            filterData={filtersData}
            onApply={onSaveFilter}
            onCancel={() => setIsDrawerOpen(false)}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            filterType={'project_timesheet_report'}
          />
        </div>
      </div>
    </div>
  )
}

export default TimeSheetHeader
