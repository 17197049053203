import {useContext, useState} from 'react'
import {ListViewPageContext} from '../../context'
import CreateTicketModal from '../modals/CreateTicketModal'
import Search from '../../../../components/Search'
import {KTSVG} from '../../../../_metronic/helpers'
import {SideFilter} from '../../../../components/SideFilter'
import useSaveFilter from '../../../projectReports/hooks/useSaveFilter'

const ListViewPageHeader = () => {
  const [showModal, setShowModal] = useState(false)
  const {hasListViewPermission, onSearch, refetchAgileBoardTicketList, filtersData} =
    useContext(ListViewPageContext)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const {isOperationLoading, onSaveFilter} = useSaveFilter(refetchAgileBoardTicketList)

  return (
    <div className='d-flex justify-content-between align-items-center mb-8'>
      <Search onSearch={onSearch} />
      <div>
        <button
          className='btn btn-outline px-6 me-5'
          id='kt_drawer_column_setting_toggle'
          onClick={() => setIsDrawerOpen(true)}
          disabled={isOperationLoading}
        >
          <KTSVG
            path='/media/ad-theme-icons/filter-icon.svg'
            className='svg-icon-5 svg-icon-gray-500 me-1'
          />
          Filters
        </button>
        <SideFilter
          filterData={filtersData}
          onApply={onSaveFilter}
          onCancel={() => setIsDrawerOpen(false)}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          filterType={'project_list_view'}
        />
        {hasListViewPermission && (
          <button type='button' onClick={() => setShowModal(true)} className='btn btn-primary'>
            <i className='fa-solid fa-plus'></i>Create Ticket
          </button>
        )}
      </div>
      {showModal && <CreateTicketModal show={showModal} onClose={() => setShowModal(false)} />}
    </div>
  )
}

export {ListViewPageHeader}
