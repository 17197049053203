import {useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
import {ActivityDrawer, InviteUsers, UpgradePlan} from '../partials'
import {MenuComponent} from '../assets/ts/components'
import {Sidebar} from './components/Sidebar'
import {ThemeModeProvider} from '../partials/layout/theme-mode/ThemeModeProvider'
import ApiV2 from '../../services/ApiV2'
import {useQuery} from 'react-query'
import LogoutModal from './components/LogoutModal'
import {useAppSelector} from '../../redux/useTypedSelector'

const fetchData = async () => {
  const response = await ApiV2.post('/ping', null, {}, {})
  return response.data
}

function AuthenticatUser() {
  const [isDialogVisible, setVisibility] = useState(false)
  const {isLoading} = useAppSelector((state) => state.auth)

  const {data, isSuccess, remove} = useQuery('authentication', fetchData, {
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    cacheTime: 1,
    enabled: !isLoading,
  })

  useEffect(() => {
    if (isSuccess && data?.message !== 'pong') {
      setVisibility(true)
      remove()
    }
    // eslint-disable-next-line
  }, [isSuccess, data])

  if (isDialogVisible) {
    return <LogoutModal show={true} />
  } else {
    return <></>
  }
}

function Layout() {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='d-flex flex-column flex-root' id='kt_toggle_navigation'>
          <div className='page d-flex flex-row flex-column-fluid'>
            <AsideDefault />
            <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
              <HeaderWrapper />

              <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                <Content>
                  <Outlet />
                </Content>
              </div>
              <Footer />
            </div>
            <Sidebar />
          </div>
        </div>

        {/* begin:: Drawers */}
        <ActivityDrawer />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <InviteUsers />
        <UpgradePlan />
        {/* end:: Modals */}
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

const MasterLayout = () => {
  return (
    <>
      <AuthenticatUser />
      <Layout />
    </>
  )
}

export {MasterLayout}
