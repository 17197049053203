import {Pagination} from '../../../utils/pagination'
import {useGetAgileBoardTicketList} from '../hooks/listView/useGetAgileBoardTicketList'
import {ListViewPageContext, ProjectDetailsContext} from '../context'
import ListViewTable from '../sections/tables/ListViewPageTables/ListViewTable'
import {ListViewPageHeader} from '../sections/headers/ListViewPageHeader'
import useGetProjectMembers from '../hooks/agileBoard/useGetProjectMembers'
import useGetProjectKanbanStages from '../hooks/agileBoard/useGetProjectKanbanStages'
import useGetProjectModules from '../hooks/agileBoard/useGetProjectModules'
import useGetProjectStatuses from '../hooks/agileBoard/useGetProjectStatuses'
import useGetProjectPriorities from '../hooks/agileBoard/useGetProjectPriorities'
import useListViewOperations from '../hooks/listView/useListViewOperations'
import {useGetAgileBoardData} from '../hooks/agileBoard/useGetAgileBoardData'
import {useContext} from 'react'

const ListView = () => {
  const {projectId, projectName, projectStatus, hasProjectPermission} =
    useContext(ProjectDetailsContext)
  const {isLoading: isLoadingAgileBoardData} = useGetAgileBoardData()
  const {projectMembers, isLoading: isLoadingProjectMembers} = useGetProjectMembers()
  const {projectKanbanStages, isLoading: isLoadingProjectKanbanStages} = useGetProjectKanbanStages()
  const {projectModules, isLoading: isLoadingProjectModules} = useGetProjectModules()
  const {projectStatuses, isLoading: isLoadingProjectStatuses} = useGetProjectStatuses()
  const {projectPriorities, isLoading: isLoadingProjectPriorities} = useGetProjectPriorities()
  const {createTicket, updateTicket, isLoading: isLoadingTicketOperation} = useListViewOperations()
  const {
    agileBoardTicketList,
    pagination,
    isLoading: isLoadingAgileBoardTicketList,
    filters,
    onSearch,
    onPageChange,
    onSortingChange,
    filtersData ,
    refetch: refetchAgileBoardTicketList,
  } = useGetAgileBoardTicketList()

  const contextValue = {
    hasListViewPermission: hasProjectPermission,

    projectId,
    projectName,
    projectStatus,

    agileBoardTicketList,
    isLoadingAgileBoardTicketList,
    filters,
    onSearch,
    onPageChange,
    onSortingChange,
    filtersData,
    refetchAgileBoardTicketList,

    isLoadingAgileBoardData,

    projectMembers,
    isLoadingProjectMembers,

    projectKanbanStages,
    isLoadingProjectKanbanStages,

    projectModules,
    isLoadingProjectModules,

    projectStatuses,
    isLoadingProjectStatuses,

    projectPriorities,
    isLoadingProjectPriorities,

    createTicket,
    updateTicket,
    isLoadingTicketOperation,
  }

  return (
    <ListViewPageContext.Provider value={contextValue}>
      <ListViewPageHeader />
      <ListViewTable />
      <Pagination pagination={pagination} onPageChange={onPageChange} />
    </ListViewPageContext.Provider>
  )
}

export default ListView
