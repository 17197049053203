/* eslint-disable */
import {useEffect, useMemo, useRef, useState} from 'react'
import {MultiSelect} from 'react-multi-select-component'
import {Controller} from 'react-hook-form'
import {dataToSelectOptions, isEmpty} from '../../utils/common'
import {MultipleSelectTypes} from './MultiSelectTypes'
import Select from '../Select/Select'

function MultipleSelect({
  id = '',
  options,
  defaultValues,
  placeholder = 'Select',
  disabled,
  error,
  isOpen,
  label,
  isRequired,
  className,
  inputClass = '',
  onMenuToggle,
  handleSelected,
  registerKey,
  control,
  rules,
  isLoading,
  isReadOnly = false,
  hasSelectAll = true,
  labelClass = '',
  labelKey = 'label',
  valueKey = 'value',
  valueType = 'string',
  onBlur,
  isClearDefaultValue = false,
  selectedValues = [],
  defaultSelectedAll = false,
  allValuesSelectedText = '',
  disabledKey = 'disabled',
}: MultipleSelectTypes) {
  const [selected, setSelected] = useState<Array<{label: string; value: string}>>([])
  const [registeredValue, setRegisteredValue] = useState<any[]>([])
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isDefaultSelected, setIsDefaultSelected] = useState(false)
  const selectRef = useRef<HTMLDivElement>(null)

  const formattedOptions =
    options && options?.length > 0
      ? labelKey && valueKey
        ? dataToSelectOptions(options, labelKey, valueKey, valueType, disabledKey)
        : dataToSelectOptions(options, 'label', 'value', valueType, disabledKey)
      : []

  useEffect(() => {
    setSelected([])
  }, [id])

  // Memoize default selected values
  const defaultSelected = useMemo(() => {
    if (!isEmpty(options)) {
      if (!isEmpty(defaultValues) && registerKey && isEmpty(registeredValue)) {
        return options.filter((option: any) => defaultValues?.includes(option?.value))
      }
      if (!isEmpty(registeredValue) && registerKey && isEmpty(defaultValues)) {
        return options.filter((option: any) => registeredValue?.includes(option?.value))
      }
      if (!isEmpty(defaultValues)) {
        return defaultValues
      }
    }
  }, [defaultValues, registeredValue, options, registerKey])

  useEffect(() => {
    if (!isEmpty(selectedValues) && !isLoading && !isDefaultSelected && !defaultValues) {
      setSelected(selectedValues)
      setIsDefaultSelected(true)
    } else if (!isEmpty(defaultValues) && !isLoading && !isDefaultSelected) {
      setSelected(defaultValues)
      setIsDefaultSelected(true)
    }
    if (isClearDefaultValue) {
      setSelected([])
    }
  }, [defaultSelected, isLoading, isDefaultSelected])

  useEffect(() => {
    if (defaultSelectedAll && options?.length && !isDefaultSelected) {
      setSelected(options)
      setIsDefaultSelected(true)
    }
  }, [defaultSelectedAll, options])

  useEffect(() => {
    if (defaultSelectedAll && options?.length && !isDefaultSelected) {
      setSelected(options)
      setIsDefaultSelected(true)
    }
  }, [defaultSelectedAll, options])

  const handleMenuToggle = (open: boolean) => {
    if (isMenuOpen && !open) {
      onBlur?.(selected)
    }
    setIsMenuOpen(open)
    onMenuToggle?.(open)
  }

  const handleChange = (values: any, onChange?: (values: string[]) => void) => {
    setSelected(values)
    handleSelected?.(values)
    onChange?.(values?.map((value: any) => value?.value))
    if (values.length === 0) {
      onBlur?.(values)
    }
  }

  const renderMultiSelect = (onChange?: (values: string[]) => void) => {
    return disabled ? (
      <Select
        id={id}
        className='text-gray-600'
        placeholder='Select'
        isRequired={true}
        options={[{label: 'Select', value: ''}]}
        disabled
      />
    ) : (
      <MultiSelect
        className={`p-0 form-select border text-capitalize ${inputClass} ${
          error ? 'is-invalid border-1 border-danger' : 'border-0'
        }`}
        key={id}
        options={formattedOptions}
        value={selected}
        onChange={(values: any) => handleChange(values, onChange)}
        labelledBy={placeholder}
        isLoading={isLoading}
        disabled={disabled || isReadOnly}
        isOpen={isOpen}
        ClearSelectedIcon={<i className='las la-times fs-4'></i>}
        onMenuToggle={handleMenuToggle}
        overrideStrings={{
          selectSomeItems: placeholder,
          allItemsAreSelected: allValuesSelectedText,
        }}
        hasSelectAll={hasSelectAll}
      />
    )
  }

  return (
    <div className={className} id={id} ref={selectRef}>
      {label && (
        <label className={`form-label ${labelClass} ${isRequired ? 'required' : ''}`}>
          {label}
        </label>
      )}
      {registerKey && control ? (
        <Controller
          name={registerKey}
          control={control}
          rules={rules}
          render={({field: {onChange, value}}) => {
            setRegisteredValue(value)
            return renderMultiSelect(onChange)
          }}
        />
      ) : (
        renderMultiSelect(() => {})
      )}
      {error?.message && <p className='invalid-feedback'>{error.message}</p>}
    </div>
  )
}

export default MultipleSelect
