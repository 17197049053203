import {isEmpty} from '../../../utils/common'

const parseTimeStringToMinutes = (timeString: string) => {
  const [hours, minutes] = timeString
    .split(/[h|m]/) // Split on 'h' or 'm'
    .map((part: string) => parseInt(part.trim(), 10) || 0)

  return hours * 60 + minutes // Convert to total minutes
}

// parsing for members table
export function dataToMembersReportRow(row: any) {
  const totalEstimationInMinutes = parseTimeStringToMinutes(row['total_estimation'])
  const totalSpentInMinutes = parseTimeStringToMinutes(row['total_spent_time'])
  const isOverEstimation = totalSpentInMinutes > totalEstimationInMinutes

  return {
    id: row['id'] || '',
    assignee: row['assignee'] || '-',
    totalCards: row['total_cards'] || 0,
    totalEstimation: row['total_estimation'] || '-',
    totalSpentTime: row['total_spent_time'] || '-',
    totalRemainingTime: row['total_remaining_time'] || '-',
    progressPercentage: row['progress_percentage'],
    isOverEstimation,
  }
}

// parsing for tasks table
export function dataToTasksReportRow(row: any) {
  const totalEstimationInMinutes = parseTimeStringToMinutes(row['estimation'])
  const totalSpentInMinutes = parseTimeStringToMinutes(row['spent_time'])
  const isOverEstimation = totalSpentInMinutes > totalEstimationInMinutes

  return {
    id: row['id'] || '',
    cardIdentifier: row['card_identifier'] || '-',
    cardTitle: row['card_title'] || '-',
    cardId: row['card_id'] || '',
    projectId: row['project_id'] || '',
    projectName: row['project_name'] || '-',
    assignee: row['assignee'] || '-',
    moduleName: row['module_name'] || '-',
    estimation: row['estimation'] || '-',
    spentTime: row['spent_time'] || '-',
    remainingTime: row['remaining_time'] || '-',
    progressPercentage: row['progress'],
    isOverEstimation,
  }
}

// parsing for time sheet table
export const timeSheetTableRowData = (data: any) => {
  if (isEmpty(data)) return {}

  return {}
}
