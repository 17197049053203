import {useContext, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {MembersReportPageContext} from '../../context'
import {useGetProjectsDropdownList} from '../../hooks/useGetProjectsDropdownList'
import {dataToSelectOptions} from '../../../../utils/common'
import {MultiSelect} from '../../../../components/MultiSelect/'
import {SideFilter} from '../../../../components/SideFilter'
import useSaveFilter from '../../hooks/useSaveFilter'

const MembersReportHeader = () => {
  const {onProjectChange, filtersData, refetch} = useContext(MembersReportPageContext)
  const {isLoading, projectsDropdownList} = useGetProjectsDropdownList()
  const options = dataToSelectOptions(projectsDropdownList, 'name', 'id')
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const {isOperationLoading, onSaveFilter} = useSaveFilter(refetch)

  return (
    <div className='d-flex justify-content-between mb-10'>
      <div>
        <MultiSelect
          id='replenishment-columns'
          options={options}
          isLoading={isLoading}
          className='w-250px'
          handleSelected={(values: any) => {
            const extractedValues = values.map((item: {value: string}) => item.value).join(',')
            onProjectChange(extractedValues)
          }}
          placeholder='Select project'
          defaultSelectedAll={true}
          allValuesSelectedText={'Project: All'}
        />
      </div>
      <div>
        <button
          className='btn btn-outline px-6'
          id='kt_drawer_column_setting_toggle'
          onClick={() => setIsDrawerOpen(true)}
          disabled={isOperationLoading}
        >
          <KTSVG
            path='/media/ad-theme-icons/filter-icon.svg'
            className='svg-icon-5 svg-icon-gray-500 me-1'
          />
          Filters
        </button>
        <SideFilter
          filterData={filtersData}
          onApply={onSaveFilter}
          onCancel={() => setIsDrawerOpen(false)}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          filterType={'project_members_report'}
        />
      </div>
    </div>
  )
}

export default MembersReportHeader
