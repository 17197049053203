import {Link, Navigate, useSearchParams} from 'react-router-dom'
import {ReactNode} from 'react'
import {PageLink} from '../../_metronic/layout/core/PageData'
import {PageTitle} from '../../_metronic/layout/core'
import {KTSVG} from '../../_metronic/helpers'

interface NavItem {
  key: string
  label: string
  component: ReactNode
  icon?: string | undefined
}

interface NavLinkProps extends Omit<NavItem, 'component'> {
  keyValue: string
  baseUrl: string
  icon?: string | undefined
}

interface NavigationProps {
  navigationData: readonly NavItem[]
  baseUrl: string
  className?: string
  defaultKey?: string
  breadcrumbs?: Array<PageLink> | boolean
  pageTitle?: string | boolean
  isLoading?: boolean
}

const NavLink = ({keyValue, label, baseUrl, icon}: NavLinkProps) => {
  const [searchParams] = useSearchParams()
  const currentView = searchParams.get('view')
  const isActive = currentView === keyValue

  return (
    <li className='nav-item'>
      <Link
        className={`nav-link text-active-primary me-6 ${isActive ? 'active' : ''}`}
        to={`${baseUrl}?view=${keyValue}`}
      >
        {icon && <KTSVG path={icon} className='me-2' />}
        {label}
      </Link>
    </li>
  )
}

const Navigation = ({
  navigationData,
  baseUrl,
  className = '',
  defaultKey = navigationData?.[0]?.key,
  breadcrumbs = false,
  isLoading,
  pageTitle: propPageTitle = false,
}: NavigationProps) => {
  const [searchParams] = useSearchParams()
  const currentKey = searchParams.get('view') || defaultKey

  if (!navigationData?.length) {
    return (
      <>
        {(typeof breadcrumbs !== 'boolean' || typeof propPageTitle !== 'boolean') &&
          (!isLoading ? (
            <PageTitle breadcrumbs={typeof breadcrumbs !== 'boolean' ? breadcrumbs : []}>
              {typeof propPageTitle !== 'boolean' ? propPageTitle : undefined}
            </PageTitle>
          ) : (
            <PageTitle
              className='placeholder bg-gray-200 w-150px h-30px rounded-2'
              content={
                typeof breadcrumbs !== 'boolean' ? (
                  <span className='placeholder bg-gray-200 w-200px h-10px mt-2 rounded-3'></span>
                ) : null
              }
            >
              {' '}
            </PageTitle>
          ))}
      </>
    )
  }

  const isValidKey = navigationData.map((item) => item.key).includes(currentKey)
  const currentNavItem = navigationData.find((item) => item.key === currentKey)

  const pageTitle =
    typeof propPageTitle !== 'boolean'
      ? propPageTitle
      : breadcrumbs
      ? currentNavItem?.label
      : undefined

  if (!searchParams.get('view')) {
    return <Navigate to={`${baseUrl}?view=${defaultKey}`} replace />
  }

  if (!isValidKey) {
    return <Navigate to={`${baseUrl}?view=${defaultKey}`} replace />
  }

  return (
    <>
      {typeof breadcrumbs !== 'boolean' || typeof propPageTitle !== 'boolean' ? (
        !isLoading ? (
          <PageTitle breadcrumbs={typeof breadcrumbs !== 'boolean' ? breadcrumbs : []}>
            {pageTitle}
          </PageTitle>
        ) : (
          <PageTitle
            className='placeholder bg-gray-200 w-150px h-30px rounded-2'
            content={
              typeof breadcrumbs !== 'boolean' ? (
                <span className='placeholder bg-gray-200 w-200px h-10px mt-2 rounded-3'></span>
              ) : null
            }
          >
            {' '}
          </PageTitle>
        )
      ) : null}

      <ul
        className={`nav nav-stretch nav-line-tabs nav-line-tabs-2x fs-5 fw-bold flex-nowrap mb-10 ${className}`}
      >
        {navigationData.map((item) => (
          <NavLink
            key={item.key}
            keyValue={item.key}
            label={item.label}
            baseUrl={baseUrl}
            icon={item.icon}
          />
        ))}
      </ul>
      {currentNavItem?.component}
    </>
  )
}

export default Navigation
