/* eslint-disable */
import {DatePicker} from 'rsuite'
import InputDateTypes from './inputDateTypes'
import {isEmpty} from '../../utils/common'
import {useEffect, useState, useRef} from 'react'
import {Controller} from 'react-hook-form'
import {getFormattedDate} from '../../utils/date'

const InputDate = ({
  id,
  className = '',
  inputClass = '',
  labelClass = '',
  isRequired = false,
  label,
  onChange,
  format = 'dd MMM yyyy',
  menuClassName = 'input-date-picker',
  placement = 'auto',
  error,
  errorClass,
  cleanable = true,
  value,
  control,
  registerKey,
  defaultValue,
  placeholder = 'DD MMM YYYY',
  isLoading,
  isDisabled,
  isReadOnly,
  onBlur,
  rules,
  floatingElement = true,
  autoFocus = false,
  ...rest
}: InputDateTypes) => {
  const [selectedTime, setSelectedTime] = useState<Date | null>(
    !isEmpty(value) && value !== 'None' ? new Date(value) : null
  )
  const [registeredValue, setRegisteredValue] = useState<any>(null)
  const datePickerRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setSelectedTime(
      !isEmpty(registeredValue) && registeredValue !== 'None'
        ? new Date(registeredValue)
        : !isEmpty(value) && value !== 'None'
        ? new Date(value)
        : null
    )
  }, [value, registeredValue])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (datePickerRef?.current && !datePickerRef?.current?.contains(event?.target as Node)) {
        onBlur?.(selectedTime)
        setIsOpen(false)
      }
    }

    document?.addEventListener('mousedown', handleClickOutside)
    return () => {
      document?.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onBlur, selectedTime])

  useEffect(() => {
    if (!autoFocus) return
    const timeoutId = setTimeout(() => {
      const inputElement = datePickerRef.current?.querySelector('input')
      if (inputElement && !isDisabled && !isReadOnly) {
        inputElement.focus()
        setIsOpen(true)
      }
    }, 0)
    return () => clearTimeout(timeoutId)
  }, [isDisabled, isReadOnly, autoFocus])

  const handleDateChange = (time: any, onChange?: (value: string | null) => void) => {
    setSelectedTime(time)
    if (!registerKey || !control) {
      onChange?.(time ? time : null)
    } else {
      onChange?.(time ? getFormattedDate(time) : null)
    }
    setIsOpen(false)
  }

  const renderDatePicker = (onChange?: (value: any) => void) => (
    <DatePicker<any>
      id={id}
      className={`ad-date-picker d-flex w-100 z-index-0 position-relative px-0 ${inputClass}`}
      placement={placement}
      menuClassName={menuClassName}
      format={format}
      cleanable={cleanable}
      onChange={(time) => handleDateChange(time, onChange)}
      value={selectedTime}
      defaultValue={
        !isEmpty(defaultValue) && defaultValue !== 'None' ? new Date(defaultValue) : null
      }
      placeholder={placeholder}
      disabled={isDisabled}
      readOnly={isLoading || isReadOnly}
      container={!floatingElement ? () => datePickerRef.current as HTMLElement : undefined}
      open={autoFocus ? isOpen : undefined}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      {...rest}
    />
  )

  return (
    <div className={className} id={id}>
      {label && (
        <label className={`form-label ${labelClass} ${isRequired ? 'required' : ''}`}>
          {label}
        </label>
      )}
      <style>
        {isLoading &&
          `#${id} .rs-stack-item {
            .rs-icon{
                display: none;
              }
            }
          `}
      </style>
      <div className='position-relative' ref={datePickerRef}>
        {registerKey && control ? (
          <Controller
            name={registerKey}
            control={control}
            rules={rules}
            render={({field: {onChange, value}}) => {
              setRegisteredValue(value)
              return renderDatePicker(onChange)
            }}
          />
        ) : (
          renderDatePicker(onChange)
        )}

        {isLoading && (
          <div className='form-field-loader'>
            <span className='text-primary spinner-border spinner-border-sm align-middle z-index-1 bg-white'></span>
          </div>
        )}

        {error && error?.message ? (
          <p className={`invalid-feedback ${errorClass}`}>{error.message}</p>
        ) : null}
      </div>
    </div>
  )
}

export default InputDate
