import * as Yup from 'yup'

export const TicketDetailsFormSchema = Yup.object().shape({
  title: Yup.string()
    .required('Ticket Name is required')
    .max(255, 'Ticket Name cannot be longer than 255 characters'),
})

export const AddSpentTimeFormSchema = Yup.object().shape({
  spent_time_created_at: Yup.date().required('Date Is Required'),
  spent_time: Yup.string().required('Spent Time is required'),
})

export const EditSpentTimeFormSchema = Yup.object().shape({
  spent_time_created_at: Yup.date().required('Date Is Required'),
  spent_time_diff: Yup.string().required('Spent Time is required'),
})